<template>
    <div id="schedule" :class="{isFilterOpen: checkFilterOpen}">
        <loader-component v-if="scheduleListProgress"></loader-component>
        <mds-layout-grid>
            <mds-row class="schedule_column">
                <mds-col :cols="12" class="schedule_column">
                    <div
                        :class="[
                            { showFilterLeftBlock: showFilterPanel },
                            'leftBlock',
                        ]"
                        :style="cssVariables"
                    >
                        <div class="scheduleHeaderSection">
                            <div>
                                <h2 class="schedule-header">Schedules</h2>
                            </div>
                            <div
                                class="headerActionButton"
                                style="margin-top: 25px;"
                            >
                                <header-button-panel
                                    :buttonDetails="buttonPanelDetails"
                                    :counterDetails="counterDetails"
                                    @panelButtonClicked="onClickPanelButton"
                                    sourceFile="schedule"
                                ></header-button-panel>
                            </div>
                        </div>
                        <div class="scheduleTable">
                            <div class="schedule_table">
                                <mds-table
                                    v-if="scheduleLength > 0"
                                    :key="tablekey"
                                    multiselection
                                    row-hover
                                    show-sortable
                                    class="grouped-row-example"
                                >
                                    <mds-thead
                                        @mds-thead-multiselection-changed="
                                            handleMultiSelection($event)
                                        "
                                        :indeterminate="
                                            selectionStatus.isIndeterminate
                                        "
                                        :checked="selectionStatus.isAllSelected"
                                    >
                                        <mds-th
                                            v-for="(
                                                header, index
                                            ) in scheduleHeader"
                                            :key="index"
                                            :width="header.width"
                                            :sortable="header.sortable"
                                            :sorted="header.sorted"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                            @mds-th-sort-changed="
                                                handleSortEvent(
                                                    index,
                                                    header.fieldName,
                                                    $event
                                                )
                                            "
                                        >
                                            {{ header.text }}
                                        </mds-th>
                                    </mds-thead>
                                    <mds-tbody>
                                        <mds-tr
                                            v-for="(row, index) in scheduleRow"
                                            :key="index"
                                            :checked="row.checked"
                                            :multiselection-label="row.name"
                                            @mds-tr-multiselection-changed="
                                                handleRowSelectEvent(
                                                    index,
                                                    $event,
                                                    row
                                                )
                                            "
                                        >
                                            <mds-td
                                                v-for="(
                                                    header, i
                                                ) in scheduleHeader"
                                                :key="i"
                                                :right-aligned="
                                                    header.align === 'right'
                                                "
                                            >
                                                <template
                                                    v-if="
                                                        header.fieldName ===
                                                        'feedname'
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            schedule-detail-router
                                                        "
                                                        @click="
                                                            showSchedulePage(
                                                                row.scheduledfeedid,row.methodtypeid
                                                            )
                                                        "
                                                        >{{
                                                            row[
                                                                header.fieldName
                                                            ]
                                                        }}</span
                                                    >
                                                </template>
                                                <template
                                                    v-else-if="
                                                        header.fieldName ===
                                                        'timepercent'
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            schedule-colourcode
                                                        "
                                                        :style="{
                                                            'background-color':
                                                                colorCodeParent(
                                                                    row[
                                                                        header
                                                                            .fieldName
                                                                    ]
                                                                ),
                                                        }"
                                                    ></span>
                                                    <span>{{
                                                        row[header.fieldName]
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-else-if="
                                                        header.fieldName ===
                                                        'deliverypercent'
                                                    "
                                                >
                                                    <span
                                                        class="
                                                            schedule-colourcode
                                                        "
                                                        :style="{
                                                            'background-color':
                                                                colorCodeParent(
                                                                    row[
                                                                        header
                                                                            .fieldName
                                                                    ]
                                                                ),
                                                        }"
                                                    ></span>
                                                    <span>{{
                                                        row[header.fieldName]
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-else-if="
                                                        header.fieldName ===
                                                        'active'
                                                    "
                                                >
                                                    <mds-switch
                                                        
                                                        value="logic-mode"
                                                        :checked="row.isactive === true"
                                                        size="small"
                                                        hidden-label
                                                        @change="
                                                            changeScheduleStatus(
                                                                row
                                                            )
                                                        "
                                                        >Logic Mode</mds-switch
                                                    >
                                                </template>
                                                <template
                                                    v-else-if="
                                                        header.fieldName ===
                                                        'actions'
                                                    "
                                                >
                                                    <span
                                                        ><mds-button
                                                            v-on:click="
                                                                rowEditActionButton(
                                                                    row
                                                                )
                                                            "
                                                            :disabled="
                                                                rowEditDisabledButton
                                                            "
                                                            class="actionButton"
                                                            icon="pencil"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                    <span
                                                        ><mds-button
                                                            v-on:click="
                                                                rowRunActionButton(
                                                                    row
                                                                )
                                                            "
                                                            :disabled="
                                                                rowRunDisabledButton
                                                            "
                                                            class="actionButton"
                                                            icon="play-circle"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                    <span
                                                        ><mds-button
                                                            @click="
                                                                rowDeleteActionButton(
                                                                    row
                                                                )
                                                            "
                                                            :disabled="
                                                                rowDeleteDisabledButton
                                                            "
                                                            class="actionButton"
                                                            icon="trash"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                </template>
                                                <template
                                                    v-else-if="
                                                        header.fieldName ===
                                                        'nextoccurrence'
                                                    "
                                                >
                                                    {{
                                                    convertUTCDate(
                                                        row[header.fieldName]
                                                    )
                                                }}
                                                </template>
                                                <template v-else>
                                                    {{ row[header.fieldName] }}
                                                </template>
                                            </mds-td>
                                        </mds-tr>
                                    </mds-tbody>
                                </mds-table>

                                <mds-empty-state
                                    v-if="scheduleRow == undefined"
                                    class="no-results-message"
                                    size="medium"
                                    title="No results matched"
                                    message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                                ></mds-empty-state>
                            </div>
                            <mds-pagination
                                :class="[
                                showFilterPanel === true
                                        ? 'move-pagination-show-info'
                                        : '',
                                    'pagination-component',
                                ]"
                                v-if="scheduleLength > 0"
                                :key="paginationComponentKey"
                                show-items-info
                                show-items-select
                                :total-items="totalItems"
                                :pageSize="pageSize"
                                :pageSizes="[10, 20, 50]"
                                @mds-pagination-page-changed="paginateTable"
                            ></mds-pagination>
                        </div>
                    </div>
                </mds-col>

                <div
                    :class="[
                        { showFilterRightBlock: showFilterPanel },
                        'rightBlock',
                    ]"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @filterEvent="searchFilter"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                searchInputPlaceHolder="Search feeds and reports"
                                @showFilterPanel="openFilterPanel"
                                :showSearchInput="true"
                                @clearInputFilter="clearTextFilter"
                                @clearAllFilters="clearAllFilters"
                                @date="date"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import FilterPanel from "../../../common_components/FilterPanel.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsPagination from "@mds/pagination";
import HeaderButtonPanel from "../../../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";
import { MdsButton } from "@mds/button";
import MdsSwitch from "@mds/switch";
import { dateFormatterMixin } from "../../../../mixins/date.js";

export default {
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsPagination,
        FilterPanel,
        NotificationComponent,
        LoaderComponent,
        HeaderButtonPanel,
        MdsEmptyState,
        MdsButton,
        MdsSwitch,
    },

    name: "schedules",
    mixins: [dateFormatterMixin],

    data() {
        return {
            showLoader: false,
            showFilterPanel: false,
            isFilterPanelOpen: true,
            showNotification: false,
            checkBoxFilterKey: 0,
            //pagination
            firstItem: "",
            lastItem: "",
            checkFilterOpen: false
        };
    },

    props: {
        buttonPanelDetails: {
            type: Array,
            default: () => [],
        },
        counterDetails: {
            type: Object,
            default: () => {},
        },
        filterObject: {
            type: Array,
            default: () => [],
        },
        menuwidth: {
            type: String,
            default: "",
        },
        rowRunDisabledButton: {
            type: Boolean,
            default: false,
        },
        rowEditDisabledButton: {
            type: Boolean,
            default: false,
        },
        rowDeleteDisabledButton: {
            type: Boolean,
            default: false,
        },
        scheduleHeader: {
            type: Array,
            default: () => [],
        },
        scheduleRow: {
            type: Array,
        },
        scheduleExpandHeader: {
            type: Array,
            default: () => [],
        },
        scheduleExpandRow: {
            type: Array,
            default: () => [],
        },
        totalItems: {
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        },
        scheduleListProgress: {
            type: Boolean,
            default: false,
        },
        paginationComponentKey: {
            type: Number,
            default: 0,
        },
        tablekey:{
            type: Number,
            default: 0,
        }
    },

    computed: {
        scheduleLength() {
            if (this.scheduleRow !== undefined) {
                if (this.scheduleRow.length > 0) {
                    return this.scheduleRow.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        selectionStatus() {
            const filtered = this.scheduleRow.filter(
                (row) => row.checked === true
            );

            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.scheduleRow.length,
                isAllSelected:
                    filtered.length === this.scheduleRow.length &&
                    filtered.length != 0 &&
                    this.scheduleRow != 0,
            };
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },
    },

    mounted() {},

    methods: {
        showSchedulePage(scheduledfeedid,methodtypeid) {
            this.$emit("showScheduleDetailsPage", scheduledfeedid, methodtypeid);
        },
        rowEditActionButton(value) {
            this.$emit("rowEditActionButton", value);
        },
        rowRunActionButton(value) {
            this.$emit("rowRunActionButton", value);
        },
        rowDeleteActionButton(value) {
            this.$emit("rowDeleteActionButton", value);
        },

        //Start Filter Panel
        openFilterPanel(selectedFilter) {
            this.checkFilterOpen = true;
            this.showFilterPanel = true;
            this.$emit("openFilterPanel", selectedFilter);
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        clearTextFilter() {
            this.$emit("clearTextFilter");
        },

        clearAllFilters() {
            this.$emit("clearAllFilters");
        },

        searchFilter(searchText) {
            this.$emit("searchFilter", searchText);
        },

        date(date, type) {
            this.$emit("date", date, type);
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.$emit(
                "multiSelectFilterChange",
                event,
                value,
                headerTypeDetails
            );
        },
        //End Filter Panel

        //pagination
        paginateTable(arg) {
            this.firstItem = arg.firstItem;
            this.lastItem = arg.lastItem;
            this.$emit("paginateTable", arg);
        },

        handleRowSelectEvent(index, event, row) {
            this.$emit("handleRowSelectEvent", index, event, row);
        },

        handleMultiSelection(event) {
            this.$emit("handleMultiSelection", event);
        },

        onClickPanelButton(event) {
            this.$emit("onClickPanelButton", event);
        },

        colorCodeParent(percent) {
            if (percent == "100% On Time" || percent == "100% Delivered") {
                return "#00AF41";
            } else if (percent === '\u2014') {
                return "";
            } else {
                return "#FF0000";
            }
        },

        changeScheduleStatus(row) {
            this.$emit("changeScheduleStatus", row);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.isFilterOpen {width: 82%}

.schedule-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.scheduleTable {
    margin-top: 13px;

    .schedule_table {
        margin: 0px;
        height: auto;
        max-height: 70vh;
        position: relative;
        overflow: auto;
    }

    .pagination-component {
        position: fixed;
        width: calc(100vw - 88px - var(--menuWidth));
        bottom: 0;
    }
}

.tableDataRow {
    padding: 0;
}

.leftBlock {
    max-width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    vertical-align: bottom;
    width: 15px;
}

::v-deep .mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    text-align: left;
    vertical-align: bottom;
    width: 2%;
}

::v-deep .mds-data-table__table___VueMDS3Demo {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    color: #1e1e1e;
    display: table;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;
    margin-top: 16px;
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.schedule-colourcode {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3%;
}
.schedule-detail-router {
    cursor: pointer;
}
.schedule_column {
    margin: 0px;
    padding: 0px;
}
</style>
