<template>
    <div>
        <schedules
            :buttonPanelDetails="buttonPanelDetails"
            :counterDetails="counterDetails"
            :filterObject="filterObject"
            :scheduleHeader="scheduleHeader"
            :scheduleRow="scheduleRow"
            :scheduleListProgress="scheduleListProgress"
            :totalItems="totalData"
            :pageSize="pageSize"
            :page="page"
            :menuwidth="menuwidth"
            :rowRunDisabledButton="rowRunDisabledButton"
            :rowEditDisabledButton="rowEditDisabledButton"
            :rowDeleteDisabledButton="rowDeleteDisabledButton"
            :paginationComponentKey="paginationComponentKey"
            :tablekey="tablekey"
            @onClickPanelButton="onClickPanelButton"
            @rowRunActionButton="rowRunActionButton"
            @rowEditActionButton="rowEditActionButton"
            @rowDeleteActionButton="rowDeleteActionButton"
            @multiSelectFilterChange="multiSelectFilterChange"
            @searchFilter="searchFilter"
            @openFilterPanel="openFilterPanel"
            @handleRowSelectEvent="handleRowSelectEvent"
            @handleMultiSelection="handleMultiSelection"
            @paginateTable="paginateTable"
            @clearTextFilter="clearTextFilter"
            @clearAllFilters="clearAllFilters"
            @changeScheduleStatus="changeScheduleStatus"
            @date="date"
            @showScheduleDetailsPage="showScheduleDetailsPage"
        >
        </schedules>
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            checkboxLabel="Yes, delete this schedule."
            deleteLabel="schedule"
            @cancel="closeDialogPopup"
            @emitDeletePopup="deletePopupModal"
        >
        </dialog-popup>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { SCHEDULE_ACTIONS } from "@/store/modules/schedule/contants";
import Schedules from "../components/Schedules.vue";
import DialogPopup from "@/components/common_components/DialogPopup.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    beforeCreate() {
        this.$store = store;
    },
    components: {
        Schedules,
        DialogPopup,
        NotificationComponent,
    },
    data() {
        return {
            counterDetails: {
                displayCounterDetails: true,
                count: 0,
                countText: "Selected",
                showVerticalLine: true,
            },
            showNotification: false,
            notificationMessage: "",
            notificationType: "",

            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Create Schedule",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: true,
                    isText: false,
                },
                {
                    id: "DeleteButtonId",
                    buttonName: "Delete Schedule",
                    iconName: "trash",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                },
            ],

            scheduleHeader: [
                {
                    fieldName: "feedname",
                    text: "Feed/Report Name",
                    width: "200px",
                },
                {
                    fieldName: "partner",
                    text: "Partner",
                    width: "130px",
                },
                {
                    fieldName: "schedule",
                    text: "Schedule",
                    width: "150px",
                },
                {
                    fieldName: "formattext",
                    text: "Format",
                    width: "110px",
                },
                {
                    fieldName: "deliverymethod",
                    text: "Delivery Method",
                    width: "110px",
                },
                {
                    fieldName: "nextoccurrence",
                    text: "Next delivery",
                    width: "150px",
                },
                {
                    fieldName: "timepercent",
                    text: "* On Time",
                    width: "150px",
                },
                {
                    fieldName: "deliverypercent",
                    text: "* Delivered",
                    width: "150px",
                },
                {
                    fieldName: "active",
                    text: "Active",
                    width: "100px",
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "110px",
                    align: "right",
                },
            ],

            scheduleRow: [],
            totalData: 0,
            page: 1,
            pageSize: 10,
            orderByColumn: "",
            sortType: false,
            paginationComponentKey: 0,

            selectedDate: "",
            toggleDialog: false,
            selectedAction: "",
            subTitle: "",
            dialogTitle: "",

            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
            ],
            searchPartnerFilter: "",
            searchDeliveryFilter: "",
            searchFilterText: "",
            todaysDate: "",
            ScheduledFeedId: 0,
            filterSearchPartner: [],
            filterSearchDeliveryMethod: [],
            rowRunDisabledButton: false,
            rowEditDisabledButton: false,
            rowDeleteDisabledButton: false,
            selectMultiRowData: [],
            expandedScheduleRowId: 0,
            itemTotalCount: new Set(),
            clearCheckboxFilter: false,
            message: "",
            tablekey:0,
        };
    },
    watch: {
        clearCheckboxFilter() {
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = false;
        },
    },
    created() {
        this.getTodaysDate();
        this.commonFunction();
        this.cachedDispatch(SCHEDULE_ACTIONS.FETCH_FILTER_PARTNER_LIST);
        this.cachedDispatch(SCHEDULE_ACTIONS.FETCH_FILTER_DELIVERYMETHOD_LIST);
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            filterPartnersList(state) {
                return get(state, "schedule.filterPartners.filterPartners", []);
            },
            filterDeliveryMethodsList(state) {
                return get(
                    state,
                    "schedule.filterDeliveryMethods.filterDeliveryMethods",
                    []
                );
            },
            scheduleList(state) {
                return get(state, "schedule.schedules.schedules", []);
            },
            // scheduleListProgress(state) {

            //     const demo = schedule ? get(state, "schedule.schedules.__loading__", false) &&
            //     get(state, "schedule.schedules.__loading__", false) : get(state, "schedule.schedules.__loading__", false)
            //     return demo;
            // },
            scheduleListProgress(state) {
                return get(state, "schedule.schedules.__loading__", false);
            },
            topDeleteIcon() {
                return this.buttonPanelDetails.filter(
                    (x) => x.id === "DeleteButtonId"
                );
            },
            scheduleDelete(state) {
                return get(
                    state,
                    "schedule.deleteSchedules.deleteSchedules",
                    []
                );
            },
            scheduleDeleteError(state) {
                return get(state, "schedule.deleteSchedules.__error__", null);
            },
            runScheduleResponse(state) {
                return get(state, "schedule.runSchedule.runSchedule", []);
            },
            runScheduleError(state) {
                return get(state, "schedule.runSchedule.__error__", null);
            },
            enableScheduleError(state) {
                return get(state, "schedule.enableSchedules.__error__", null);
            },
            enableSchedule(state) {
                return get(
                    state,
                    "schedule.enableSchedules.enableSchedules",
                    []
                );
            },
            downloadScheduleResponse(state) {
                return get(
                    state,
                    "schedule.downloadSchedule.downloadSchedule",
                    []
                );
            },
        }),
    },
    props: {
        menuwidth: {
            type: String,
        },
    },

    methods: {
        showScheduleDetailsPage(scheduledfeedid) {
            this.$router.push(`/data-dissemination/schedules/detail?ScheduledFeedId=${scheduledfeedid}&date=${this.selectedDate}`);
        },

        // event for on click filter panel
        onClickPanelButton(val) {
            if (val === "Create Schedule") {
                this.createSchedule();
            } else if (val === "Delete Schedule") {
                this.deleteSchedule();
            }
        },
        // event on top create action
        createSchedule() {
            this.$router.push({
                name: "Data Dissemination Create Schedule",
            });
        },
        // event for top delete action
        deleteSchedule() {
            this.subTitle = `This action cannot be undone. The delivery schedule and any previously generated files will be deleted for all users with access.`;
            this.dialogTitle = "Delete this delivery schedule?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
        },
        // event for delete action
        async deletePopupModal() {
            this.toggleDialog = false;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.scheduledfeedid);
            });
            var ScheduledFeedId = array.join(",");
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_DELETE_SCHEDULE_LIST,
                {
                    ScheduledFeedId,
                }
            );
            let response = this.scheduleDelete;
            const obj = {
                response,
                ntfnMsg: "The schedule has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
                ntfnfailureMsg: this.scheduleDeleteError,
            };

            this.notificationResponse(obj);

            this.counterDetails.count = 0;
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.commonFunction();
        },

        // event for closing delete dialog popup
        closeDialogPopup() {
            this.toggleDialog = false;
        },

        // evet for getting all schedule list
        async commonFunction(param) {
            var data = {
                page: this.page,
                pageSize: this.pageSize,
                date: this.selectedDate,
                searchFeed: this.searchFilterText,
                searchPartner: this.searchPartnerFilter,
                searchDelivery: this.searchDeliveryFilter,
                orderByColumn: this.orderByColumn,
                isAscending: this.sortType,
            };
            await this.$store.dispatch(SCHEDULE_ACTIONS.FETCH_SCHEDULE_LIST, {
                data,
            });
            let response = this.scheduleList;
            if (param && param.size) {
                for (let item of param) {
                    for (let i = 0; i < response.schedules.length; i++) {
                        if (
                            response.schedules[i].scheduledfeedid ===
                            item.scheduledfeedid
                        ) {
                            response.schedules[i] = item;
                        }
                    }
                }
            }
            this.scheduleRow = response.schedules;
            if (this.scheduleRow != undefined) {
                this.scheduleRow.forEach((item) => {
                    item.timepercent =
                        item.timepercent === null
                            ? "\u2014"
                            : Math.ceil(item.timepercent) + "% On Time";
                    item.deliverypercent =
                        item.deliverypercent === null
                            ? "\u2014"
                            : Math.ceil(item.deliverypercent) + "% Delivered";
                });
            }
            this.totalData = response.totals;
        },

        // event for search panel
        searchFilter(searchText) {
            this.page = 1;
            this.selectMultiRowData.length = 0;
            this.enableRowIcons();
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.buttonPanelDetails[1].isDisabled = true;
            this.searchFilterText = searchText;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        // event for clear text field
        clearTextFilter() {
            this.selectMultiRowData.length = 0;
            this.enableRowIcons();
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.buttonPanelDetails[1].isDisabled = true;
            this.page = 1;
            this.searchFilterText = "";
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        //row action button
        async rowRunActionButton(value) {
            let data = value.scheduledfeedid;
            await this.$store.dispatch(SCHEDULE_ACTIONS.RUN_SCHEDULE_LIST, {
                data,
            });
            let response = this.runScheduleResponse;
            const obj = {
                response,
                ntfnMsg:
                    "The schedule is running and will be delivered to the partner.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.runScheduleError,
            };
            this.notificationResponse(obj);
        },

        rowEditActionButton(value) {
            this.$router.push(`/data-dissemination/schedules/create?ScheduledFeedId=${value.scheduledfeedid}`);
        },

        rowDeleteActionButton(value) {
            this.subTitle = `This action cannot be undone. The delivery schedule and any previously generated files will be deleted for all users with access.`;
            this.dialogTitle = "Delete this delivery schedule?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(value);
            }
        },

        // event for opening filter-panel
        openFilterPanel(selectedFilter) {
            this.getPartnerFilterList(selectedFilter);
            this.getDeliveryMethodFilterList(selectedFilter);
            this.getDateFilter();
        },

        // event for getting date filter list
        getDateFilter() {
            this.filterObject[0].filterHeader = "Date";
            this.filterObject[0].selectionType = "date";
            var array = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "date",
                    value: new Date (this.selectedDate),
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(this.todaysDate),
                    },
                },
            ];
            this.filterObject[0].filterList = array;
        },
        // event for getting partner filter list
        getPartnerFilterList(selectedFilter) {
            let response = this.filterPartnersList;
            this.filterObject[1].filterHeader = "Partner";
            this.filterObject[1].selectionType = "multiple";
            const data =
                response &&
                response.reduce((obj, item) => {
                    const id = item.id;
                    const lable = item.name;
                    obj.push({ id, lable, isChecked: false });
                    return obj;
                }, []);
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[1].filterList = data;
        },

        // event for getting delivery method filter list
        getDeliveryMethodFilterList(selectedFilter) {
            let response = this.filterDeliveryMethodsList;
            this.filterObject[2].filterHeader = "Delivery Method";
            this.filterObject[2].selectionType = "multiple";

            const data =
                response &&
                response.reduce((obj, item, index) => {
                    const id = index;
                    const lable = item;
                    obj.push({ id, lable, isChecked: false });
                    return obj;
                }, []);
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[2].filterList = data;
        },

        // event for clear all filter function
        clearAllFilters() {
            this.clearCheckboxFilter = true;
            this.counterDetails.count = 0;
            this.reset();
            this.clearTextFilter();
        },

        // multi-select Filter Panel
        async multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            this.getDateFilter();
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (
                        let j = 0;
                        j < this.filterObject[i].filterList.length;
                        j++
                    ) {
                        if (
                            this.filterObject[i].filterList[j].lable ==
                            value.lable
                        ) {
                            this.filterObject[i].filterList[j].isChecked =
                                event;
                        }
                    }
                }
            }
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.disableTopIcons("noSelect");
            if (event == true) {
                this.showLoader = true;
                if (headerTypeDetails == "Partner") {
                    this.filterSearchPartner.push(value.lable);
                } else if (headerTypeDetails == "Delivery Method") {
                    this.filterSearchDeliveryMethod.push(value.lable);
                }
                this.searchPartnerFilter = this.filterSearchPartner.join(",");
                this.searchDeliveryFilter =
                    this.filterSearchDeliveryMethod.join(",");
            } else if (event == false) {
                this.showLoader = true;
                if (headerTypeDetails == "Partner") {
                    for (let i = 0; i < this.filterSearchPartner.length; i++) {
                        if (this.filterSearchPartner[i] === value.lable) {
                            this.filterSearchPartner.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Delivery Method") {
                    for (
                        let i = 0;
                        i < this.filterSearchDeliveryMethod.length;
                        i++
                    ) {
                        if (this.filterSearchDeliveryMethod[i] == value.lable) {
                            this.filterSearchDeliveryMethod.splice(i, 1);
                        }
                    }
                }
                this.searchPartnerFilter = this.filterSearchPartner.join(",");
                this.searchDeliveryFilter =
                    this.filterSearchDeliveryMethod.join(",");
            }
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        // event for pagination
        paginateTable(arg) {
            const selectedItem = this.itemTotalCount;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.commonFunction(selectedItem);
        },

        // event for multiselection
        handleMultiSelection(event) {
            for (let i = 0; i < this.scheduleRow.length; i++) {
                this.$set(this.scheduleRow[i], "checked", event.target.checked);
            }
        },

        enableRowIcons() {
            this.rowRunDisabledButton = false;
            this.rowDeleteDisabledButton = false;
            this.rowEditDisabledButton = false;
        },

        disableRowIcons() {
            this.rowRunDisabledButton = true;
            this.rowDeleteDisabledButton = true;
            this.rowEditDisabledButton = true;
        },

        // event for single row selection
        handleRowSelectEvent(index, event, row) {
            this.selectMultiRowData = this.scheduleRow.filter(
                (el) => el.checked
            );

            this.$set(this.scheduleRow[index], "checked", event);
            if (event) {
                this.itemTotalCount.add(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            } else {
                this.itemTotalCount.delete(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            }
            if (this.selectMultiRowData.length === 1) {
                this.disableTopIcons("singleSelect");
                this.disableRowIcons();
            }
            if (this.selectMultiRowData.length > 1) {
                this.disableTopIcons("singleSelect");
                this.disableRowIcons();
            }
            if (!this.selectMultiRowData.length) {
                this.disableTopIcons("noSelect");
                this.enableRowIcons();
            }

            this.counterDetails.count = this.itemTotalCount.size;
        },

        // event for disabling top icons
        disableTopIcons(params) {
            this.topDeleteIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
        },

        // change enable disable active switch
        async changeScheduleStatus(row) {
            if (row.isactive === true) {
                this.message = "The Schedule is disabled";
            } else {
                this.message = "The Schedule is enabled";
            }
            var data = {
                ScheduledFeedId: row.scheduledfeedid,
                enable: !row.isactive,
            };
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_ENABLE_SCHEDULE_LIST,
                {
                    data,
                }
            );
            let response = this.enableSchedule;
            const obj = {
                response,
                ntfnMsg: this.message,
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.enableScheduleError,
            };
            this.notificationResponse(obj);
            if (this.enableScheduleError != null) {
                this.commonFunction();
                this.tablekey+=1;
            }
        },

        date(date) {
            this.selectedDate = date.toLocaleDateString();
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        // notification event
        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                this.commonFunction();
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        // reset Schedule page
        reset() {
            let selectedCheckboxes = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            for (let i = 0; i < selectedCheckboxes.length; i++) {
                if (!selectedCheckboxes[i].getAttribute('role') && selectedCheckboxes[i].getAttribute('role') !== "switch") {
                    selectedCheckboxes[i].checked = false;
                }
            }
            this.enableRowIcons();
            this.searchFilterText = "";
            this.searchPartnerFilter = "";
            this.searchDeliveryFilter = "";
            this.filterSearchPartner = [];
            this.filterSearchDeliveryMethod = [];
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.getTodaysDate();
            this.getDateFilter();
        },

        getTodaysDate() {
            // code for today's date
            let response = new Date().toLocaleDateString();
            let temp = response.split("/");
            if (temp[0].length == 1) {
                temp[0] = "0" + temp[0];
            }
            if (temp[1].length == 1) {
                temp[1] = "0" + temp[1];
            }
            this.todaysDate = temp[2] + "," + temp[0] + "," + temp[1];
            this.selectedDate = this.todaysDate;
        },
    },
};
</script>

